import React from 'react';

import { PropTypes } from 'prop-types';

import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import interviewImage from '../../content/books/images/interview-prep-guide.jpg';
import pmMetricsImage from '../../content/books/images/pm-metrics-guide.jpg';
import prioritizationImage from '../../content/books/images/prioritization-guide.jpg';
import retrosImage from '../../content/books/images/retros-guide.jpg';
import riceImage from '../../content/books/images/rice-guide.jpeg';
import testingImage from '../../content/books/images/testing-guide.jpg';
import userStoryImage from '../../content/books/images/user-stories-guide.png';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import TopBanner from '../components/TopBanner';
import { BOOKS } from '../constants';
import bannerImg from '../images/books.png';

import {
    Box,
    Button,
    ButtonBase,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Container,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const DOWNLOAD_LINK = 'download';
const IMAGES = [
    riceImage,
    pmMetricsImage,
    interviewImage,
    userStoryImage,
    prioritizationImage,
    retrosImage,
    testingImage,
];

const StyledImage = styled('img')(() => ({
    width: '325px',
    objectFit: 'cover',
    height: '150px',
}));
function Books(props) {
    return (
        <Layout>
            <Seo title="Product Management E-Books | Free, downloadable guides" />
            <TopBanner bannerImg={bannerImg}>
                <Typography
                    color="primary"
                    variant="h3"
                    component="h1"
                    maxWidth={300}
                >
                    Product Management E-Books
                </Typography>
                <Typography variant="h5" my={3} maxWidth={600}>
                    Free downloadable resources for Product Managers
                </Typography>
            </TopBanner>

            <Container maxWidth="lg" sx={{ mt: 5 }}>
                <Typography variant="body1" my={4}>
                    As a product manager, one has to constantly keep themselves
                    updated. If you have not had the chance to check out{' '}
                    <a href="https://www.dezkr.com/pm-course/">our course</a>{' '}
                    yet or if you prefer reading books at your own pace - check
                    out the e-books below. All our e-books are completely free.
                    It is part of our mission to give back to the community of
                    product managers.
                </Typography>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-around"
                >
                    {BOOKS.map(({ slug, title, description }, idx) => (
                        <Card
                            key={idx}
                            sx={{
                                maxWidth: 325,
                                my: 2,
                                mx: 1,
                                flexBasis: 325,
                                flexShrink: 0,
                            }}
                            raised
                        >
                            <ButtonBase
                                component={Link}
                                to={`${DOWNLOAD_LINK}/${slug}`}
                                sx={{ flexDirection: 'column' }}
                            >
                                <CardMedia>
                                    <StyledImage
                                        src={IMAGES[idx]}
                                        alt={title}
                                    />
                                </CardMedia>
                                <CardContent>
                                    {renderBookTitle(title)}
                                    {renderBookDescription(description)}
                                    <CardActionArea>
                                        {renderBookLink(slug)}
                                    </CardActionArea>
                                </CardContent>
                            </ButtonBase>
                        </Card>
                    ))}
                </Box>
            </Container>
        </Layout>
    );

    function renderBookTitle(title) {
        return (
            <Typography
                variant="h6"
                color="primary"
                my={1}
                component="div"
                height="60px"
                gutterBottom
            >
                {title}
            </Typography>
        );
    }

    function renderBookDescription(desc) {
        return (
            <Typography
                variant="body1"
                my={1}
                component="div"
                height="100px"
                overflow="hidden"
                textOverflow="ellipsis"
            >
                {desc}
            </Typography>
        );
    }

    function renderBookLink(slug) {
        return (
            <Button component={Link} to={`${DOWNLOAD_LINK}/${slug}`}>
                Download now
            </Button>
        );
    }
}

export const Head = () => {
    return <Seo title="Product Management Books | Free, downloadable guides" />;
};

Books.propTypes = {};
export default Books;
